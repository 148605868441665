import React from 'react';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const ActionLogo = () => {
    return (
        <a href='/'>
            <img src="/images/AI Tools Database Logo.svg" alt="logo" /></a>
    );
}

export default ActionLogo;
