import React from "react";
import styles from './Header.module.css';

const Header = ({category}) => {
    return (
        <div className={styles.headerContainer}>
    <div style={{display: 'flex', flexDirection: 'column', rowGap: '16px', width: '100%'}}>
        <h1>{category ? category.name : 'Discover the Best AI Tools'}</h1>
        <p style={{color: 'rgba(255, 255, 255, 0.72)', textAlign: 'left'}}>{category ? category.meta_description : 'Explore over 446 best AI tools on AI Tools Database. Get detailed information to select the ideal AI solution for your needs.'}</p>
    </div>
    {/* <img className={styles.headerimg} src="/images/tools.png" /> */}
</div>
    )
}

export default Header;