import React from 'react';
import { useEffect, useState } from "react";
import styles from './SideNav.module.css';
import supabase from '../supabaseClient';
import ActionLogo from './ActionLogo';
import { Link } from 'react-router-dom';

const Sidenav = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
      async function fetchCategories() {
          let { data, error } = await supabase
              .from('Category')
              .select('*')
              .order('index');
          if (error) {
            console.log('Error:', error);
          } else {
            const idsToSkip = ['a7f2eda1-67e0-4931-9b8f-ddd0413a632a']; // Add the IDs you want to skip here
          const filteredData = data.filter(item => !idsToSkip.includes(item.id));
          setCategories(filteredData);
          }
      }
      fetchCategories();
  }, []);

  const handleSubmitButtonClick = () => {
    window.open('https://forms.gle/4smiKtHGMBMygqj46', '_blank');
  }

  return (
    <div className={styles.sideNav}>
      <ActionLogo />
     {/*<ul>
        <li>Home</li>
      </ul>
      */}
      <button className="primaryButton" onClick={handleSubmitButtonClick}>Submit Tool</button>
      <img src='/images/border268.svg' alt='divider' style={{width: '100%', height: '2px'}}/>
      <div className={styles.categoriesContainer}>
      <div className={styles.listItem} style={{fontSize: '12px', fontWeight: '600', opacity:'0.5'}}>CATEGORIES</div>
                {categories.map((category) => (
                  <Link to={`${encodeURIComponent(category.slug)}`} key={category.id}>
                     <div className={styles.listItem} key={category.id}>
                        {category.emoji}&nbsp;{category.name.replace(/AI/g, '')}
                      </div></Link>
                ))};
            </div>
            </div>
        );
    }

export default Sidenav;