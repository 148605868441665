import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../supabaseClient'; 
import Header from './Header';
import styles from './ProductDetails.module.css';
import style from './MainContainer.module.css';
import { Helmet } from 'react-helmet';

const ProductDetails  = () => {
    const handleVisitButtonClick = () => {
        window.open(`${tool.website}?ref=aitoolsdatabase.com&utm_source=aitoolsdatabase.com&utm_medium=aitoolsdatabase.com&utm_campaign=aitoolsdatabase.com`, '_blank');
      };
    const { toolTitle } = useParams();
    const [tool, setTool] = useState([]);
    const [error, setError] = useState(null); // Add error state

    useEffect(() => {
        async function fetchToolData() {
            const { data, error } = await supabase
                .from('Tool')
                .select('*')
                .eq('slug', decodeURIComponent(toolTitle))
                .single();

            if (error) {
                console.log('Error:', error);
                setError(error); // Set error state
            } else {
                setTool(data);
            }
        }

        fetchToolData();
    }, [toolTitle]);
    return (
        <div className={style.container}>
        <p onClick={() => window.history.back()} className="caption" style={{cursor: 'pointer', textAlign: 'left'}}> ← Go Back</p>
        <div className={styles.productdetails}>
            <Helmet>
            <title>{tool.title}</title>
            <meta name="description" content="{tool.description}" />
            <meta name="keywords" content={tool.title} />
        </Helmet>
        <div className={styles.toolhead}>
        <div className={styles.toolinfo}>
        <img className={styles.imgIcon} alt="" src={tool.logo} />
        <div className={styles.title}>
        <h1><div className="titleLarge">{tool.title}</div></h1>
        <div className="bodySecondary">{tool.one_liner ? tool.one_liner : "No Description Available"}</div>
        </div>
        </div>
        <button className='primaryButton' style={{maxWidth: '117px'}} onClick={handleVisitButtonClick}>Visit Website</button>
        </div>
        <p className="bodySecondary">{tool.description}</p>
        </div></div>
        );
        };

export default ProductDetails;
        