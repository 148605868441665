import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import styles from './ProductCard.module.css';
const ProductCard = ({ tool, toolCategoryList }) => {
  const [isVisitButtonVisible, setIsVisitButtonVisible] = useState(false);

  const handleVisitButtonClick = () => {
    window.open(`${tool.website}?ref=aitoolsdatabase.com&utm_source=aitoolsdatabase.com&utm_medium=aitoolsdatabase.com&utm_campaign=aitoolsdatabase.com`, '_blank');
  };

  return (
    <div
      className={styles.productcard}
      onMouseEnter={() => {
		setIsVisitButtonVisible(true)}}
      onMouseLeave={() => {
		setIsVisitButtonVisible(false)}}
    >
      <div className={styles.content}>
        <div className={styles.logoTitle}>
          <img className={styles.imgIcon} alt="" src={tool.logo}/>
          <div className="title">{tool.title}</div>
        </div>
        <div style={{minHeight: '40px'}} className="bodySmallSecondary">
		{tool.one_liner ? tool.one_liner : "No Description Available"}
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.primaryCategory}>
          <div className="caption">{tool.category}</div>
        </div>
        {isVisitButtonVisible && (
          <div
            className={styles.visitButton}
            onClick={handleVisitButtonClick}
          >
            <img
              className={styles.eyeIcon}
              alt=""
              src="/images/Eye.svg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;