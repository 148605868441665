import React from 'react';
import ActionLogo from './ActionLogo';
import styles from './MobileNavbar.module.css';

const MobileNavbar = () => {
    const handleSubmitToolButtonClick = () => {
        window.open('https://forms.gle/4smiKtHGMBMygqj46', '_blank');
    };


    return (
        <div className={styles.mobileNav}>
            <ActionLogo />
            <button className="primaryButton" onClick={handleSubmitToolButtonClick}>Submit Tool</button>
        </div>
    );

}

export default MobileNavbar;