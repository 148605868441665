import React from 'react';
import { useEffect, useState } from "react";
import Header from './Header';
import styles from './MainContainer.module.css';
import ProductCard from "./ProductCard";
import supabase from '../supabaseClient';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

const CategoryPage = () => {
    const { pageCategory } = useParams();
    const [category, setCategory] = useState([]);
    const [tools, setTools] = useState([]);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalToolsCount, setTotalToolsCount] = useState(0);

    // Fetch the category details based on the pageCategory
    useEffect(() => {
        async function fetchPageCategory() {
            const { data, error } = await supabase
                .from('Category')
                .select('*')
                .eq('slug', decodeURIComponent(pageCategory))
                .single();

            if (error) {
                console.error('Error:', error);
                setError(error);
            } else {
                setCategory(data);
            }
        }

        fetchPageCategory();
    }, [pageCategory]);

    // Fetch the tools based on category and page
    useEffect(() => {
        async function fetchTools() {
            if (!category || !category.id) return;

            setLoading(true);
            const { data, error, count } = await supabase
                .from('Tool')
                .select(`*, ToolCategory!inner(*)`, { count: 'exact' })
                .eq('ToolCategory.category_id', category.id)
                .range(0, page * 15 - 1);

            if (error) {
                console.error('Error:', error);
            } else {
                setTools(data);
                setTotalToolsCount(count);
            }
            setLoading(false);
        }
        fetchTools();
    }, [category, page]);
    
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

 /*    useEffect(() => {
        fetchTools();
        const loadMore = document.getElementById('load');
        loadMore.addEventListener('click', handleLoadMore);
        return () => loadMore.removeEventListener('click', handleLoadMore);
    }, [page, pageCategory]); */
    

/*     function handleScroll() {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) return;
        setPage(prevPage => prevPage + 1);
    } */



    return (
        <div className={styles.container}>
         <Helmet>
            <meta charSet="utf-8" />
            <title>{category.name}</title>
            <meta name="description" content={category.meta_description} />
            <meta name="keywords" content={category.name} />
        </Helmet>
            <Header category={category} />
            <div className={styles.productContainer}>
                {tools.map((tool) => (
                    <Link to={`/ai/${encodeURIComponent(tool.slug)}`}>
                        <ProductCard key={tool.id} tool={tool} />
                        </Link>
                ))}
            {loading && (<p style={{color: 'white', textAlign: 'center', width: '100%'}}>Loading...</p>)}
            {!loading && tools.length < totalToolsCount &&(<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><button id='load' className='primaryButton' style={{maxWidth: '320px'}} onClick={handleLoadMore}>Load More</button></div>)}
                </div>
</div>
    )
}

export default CategoryPage;