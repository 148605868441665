import logo from './logo.svg';
import './App.css';
import './index.css';
import './global.css'; // Importing global styles
import Sidenav from './components/Sidenav';
import MainContainer from './components/MainContainer';
import CategoryPage from './components/CategoryPage';
import ProductDetails from './components/ProductDetails';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <div className="App">
      <Router>
      <Sidenav />
        <Routes>
          <Route path="/" element={<MainContainer />} />
          <Route path="/ai/:toolTitle" element={<ProductDetails />} />
          <Route path="/:pageCategory" element={<CategoryPage />} />
        </Routes>
      </Router>
      <SpeedInsights />
      <Analytics/>
    </div>
  );
}

export default App;
