import React from 'react';
import { useEffect, useState } from "react";
import Header from './Header';
import styles from './MainContainer.module.css';
import ProductCard from "./ProductCard";
import supabase from '../supabaseClient';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MobileNavbar from './MobileNavbar';

export const MainContainer = () => {
    const [tools, setTools] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalToolsCount, setTotalToolsCount] = useState(0);
    const [error, setError] = useState(null);

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        fetchTools();
    }, []);

    async function fetchTools() {
        setLoading(true);
        const { data, error, count } = await supabase
            .from('Tool')
            .select('title, one_liner, category, logo, website, slug', { count: 'exact' })
            .range(0, page * 15 - 1)
            .order('is_featured', { ascending: false });

        if (error) console.log('Error:', error);
        else {
            setTools(data);
            setTotalToolsCount(count);
        }
        setLoading(false);
    }

    /*     function handleScroll() {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) return;
            setPage(prevPage => prevPage + 1);
        } */
    useEffect(() => {
        fetchTools();
        const loadMore = document.getElementById('load');
        loadMore.addEventListener('click', handleLoadMore);
        return () => loadMore.removeEventListener('click', handleLoadMore);
    }, [page]);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI Tools Database - Best AI Directory</title>
                <meta name="description" content="Explore over 483 best AI tools on AI Tools Database. Get detailed information to select the ideal AI solution for your needs." />
                <meta name="keywords" content="AI Tools, Best AI Tools, Top AI Tools, AI Tool Directory" />
            </Helmet>
            <MobileNavbar />
            <Header />
            <div className={styles.productContainer}>
                {tools.map((tool) => (
                    <Link to={`ai/${encodeURIComponent(tool.slug)}`} key={tool.id}>
                        <ProductCard tool={tool} key={tool.id} />
                    </Link>
                ))}
                {loading && (<p style={{ color: 'white', textAlign: 'center', width: '100%' }}>Loading...</p>)}
                {!loading && tools.length < 446 && (<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><button id='load' className='primaryButton' style={{ maxWidth: '320px' }} onClick={handleLoadMore}>Load More</button></div>)}
            </div>
        </div>
    );
};

export default MainContainer;